import { action } from 'mobx';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import SubscriptionStore from './SubscriptionStore';
import ShowStore from './ShowStore';
import PodcastStore from './PodcastStore';
import BroadcastStore from './BroadcastStore';
import ImportRssStore from './ImportRssStore';
import ImportedPodcastsStore from './ImportedPodcastsStore';
import CampaignStore from './CampaignStore';
import PlaylistStore from './PlaylistStore';
import PageSettingsStore from './PageSettingsStore';
import ClipStore from './ClipStore';
import AutoSharingStore from './AutoSharingStore';
import QuotaStore from './QuotaStore';
import ListenersStatsStore from './statistics/ListenersStatsStore';
import SourcesStatsStore from './statistics/SoucesStatsStore';
import PeakTimeStatsStore from './statistics/PeakTimeStatsStore';
import ApplePodcastsStatsStore from './statistics/ApplePodcastsStatsStore';
import ClipFormatStore from './ClipFormatStore';
import CallToActionStore from './CallToActionStore';

class RootStore {
    authStore = new AuthStore(this);
    userStore = new UserStore(this);
    showStore = new ShowStore(this);
    podcastStore = new PodcastStore(this);
    subscriptionStore = new SubscriptionStore(this);
    broadcastStore = new BroadcastStore(this);
    importRssStore = new ImportRssStore(this);
    importedPodcastsStore = new ImportedPodcastsStore(this);
    campaignStore = new CampaignStore(this);
    playlistStore = new PlaylistStore(this);
    pageSettingsStore = new PageSettingsStore(this);
    autoSharingStore = new AutoSharingStore(this);
    clipStore = new ClipStore(this);
    clipFormatStore = new ClipFormatStore(this);
    listenersStatsStore = new ListenersStatsStore(this);
    sourcesStatsStore = new SourcesStatsStore(this);
    peakTimeStatsStore = new PeakTimeStatsStore(this);
    applePodcastsStatsStore = new ApplePodcastsStatsStore(this);
    quotaStore = new QuotaStore(this);
    callToActionStore = new CallToActionStore(this);

    @action
    reset() {
        Object.values(this).forEach((store) => store.reset && store.reset());
    }
}

export default new RootStore();
